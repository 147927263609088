import React, { useState, useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithRedirect, GoogleAuthProvider, signInWithPopup, getRedirectResult } from 'firebase/auth';
import "../styles/authmodal.css";
import googleLogo from '../assets/google-logo.png';  // Make sure you have this logo in your assets folder
import { isMobile } from 'react-device-detect'; // You can install react-device-detect to check for mobile devices

const AuthModal = ({ user, userData,isSignUp, setIsSignUp, closeAuthModal, setLogInTime}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();


    // console.log("ISMOBILE?: " + isMobile);

    const handleAuth = async () => {
        try {
            if (isSignUp) {
                await createUserWithEmailAndPassword(auth, email, password);
                // Get the current time in 12-hour format with AM/PM
                const currentTime = new Date().toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                setLogInTime(currentTime);
            } else {
                await signInWithEmailAndPassword(auth, email, password);
                // Get the current time in 12-hour format with AM/PM
                const currentTime = new Date().toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                setLogInTime(currentTime);
            }
            closeAuthModal();
            navigate('/');
        } catch (error) {
            console.error('Authentication error:', error.message);
            setErrorMessage(error.message);
                // Set the error message to display it on the pag}
        }
    };




    const handleGoogleSignIn = async () => {
        try {
            // Sign out the current user to force Google sign-in to ask for an account
            await auth.signOut();
    
            // Add a parameter to force account selection when signing in with Google
            provider.setCustomParameters({
                prompt: 'select_account'  // Forces the Google account chooser to appear
            });
    
            if (isMobile) {
                // Use signInWithRedirect for mobile devices
                // await signInWithRedirect(auth, provider);
                await signInWithPopup(auth, provider);
                navigate('/notes'); // Redirect to home after popup sign-in
            } else {
                // Use signInWithPopup for desktops
                await signInWithPopup(auth, provider);
                navigate('/'); // Redirect to home after popup sign-in
            }
    
            closeAuthModal();
            const currentTime = new Date().toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            setLogInTime(currentTime);
            navigate('/');
        } catch (error) {
            console.error('Google Sign-In error:', error.message);
            if (error.message === "Firebase: Error (auth/popup-closed-by-user).") {
                // Handle the popup closed by user case
            } else {
                setErrorMessage(error.message);
            }
        }
    };
    







    return (
        <div className="modal-overlay">
        <div className="auth-modal">

            <div className="free-trial-text">{isSignUp ? <div style={{fontSize: "14px"}}>Create your free workspace (Limit 15 notes). </div> : ''}</div>
            <button className="close-button" onClick={() => {closeAuthModal();}}>×</button>
            <div className="auth-tabs">
                <button 
                    className={isSignUp ? "active-tab" : ""} 
                    onClick={() => setIsSignUp(true)}
                >
                    Sign Up
                </button>
                <button 
                    className={!isSignUp ? "active-tab" : ""} 
                    onClick={() => setIsSignUp(false)}
                >
                    Log In
                </button>
            </div>

            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
            />
            <button onClick={handleAuth} style={{color: "white"}}>{isSignUp ? 'Sign Up' : 'Log In'}</button>

            {/* Google Sign-In Button */}
            <button onClick={handleGoogleSignIn} className="google-signin-button" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "white", background: "#4285f4" }}>
                <div style={{background: "white"}}><img src={googleLogo} alt="Google logo" style={{ width: '20px', height: '20px' }} /></div>
                {isSignUp ? 'Sign Up with Google' : 'Log In with Google'}
            </button>

            {/* Conditionally render the error message if it exists */}
            {errorMessage && <p className="error-message" style={{color: "red"}}>{errorMessage}</p>}
        </div>
        </div>
    );
};

export default AuthModal;