import React, { useState, useEffect, useRef, useMemo } from "react";
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig';
import Draggable from "react-draggable"; // Import Draggable
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import "../styles/note.css"
import "../styles/card-buttons.css"
import "../styles/card-resize.css"

import CardButtons from "./CardButtons"
import LinkFile from "./LinkFile";
import TextEditor from "../pages/TextEditor";
import JoditPage from "../pages/JoditPage";
import { Jodit } from "jodit-react";

function Note({ user, data, cardKey, layout, deleteVideoId, openWindow, closeCardWindowSidebar, isActive }) {
    const [cardSize, setCurrentCardSize] = useState('normal');
    const [tabletSize, setTabletSize] = useState('tablet-normal');
    const [mobileSize, setMobileSize] = useState('mobile-normal');
    const [windowOpen, setWindowOpen] = useState('window-closed');
    const [windowSize, setWindowSize] = useState('window-sm');
    const [vertical, setVertical] = useState('horizontal');
    const [showNotes, setShowNotes] = useState(false);
    const [editorValue, setEditorValue] = useState();
    const iframeRef = useRef();

        // Save The Slate Text to Local Storage
        //         const contentRef = ref(database, `videos/${data.videoId}/notes`);

        // const fetchInitialValue = async () => {
        //     const contentRef = ref(database, `videos/${data.videoId}/notes`);

        //     try {
        //         const snapshot = await get(contentRef);

        //         if (snapshot.exists()) {
        //             const content = snapshot.val();
        //             // console.log("Fetched content:", content); // Log the fetched content
    
        //             const parsedContent = JSON.parse(content);
        //             // console.log("Parsed content:", parsedContent); // Log the parsed content
                    
        //             setEditorValue(parsedContent);

        //             const initialValue = parsedContent;
        //         }
        //     } catch (error) {
        //         console.error("Failed to fetch content:", error);
        //     }
        // };


        // const fetchInitialValue = async () => {
        //     const contentRef = ref(database, `videos/${data.videoId}/notes`);
        //     try {
        //         const snapshot = await get(contentRef);
        //         if (snapshot.exists()) {
        //             const content = snapshot.val();
        //             console.log("Fetched content:", content); // Log the fetched content
    
        //             const parsedContent = JSON.parse(content);
        //             console.log("Parsed content:", parsedContent); // Log the parsed content
    
        //             setEditorValue(content);
        //             console.log(parsedContent)
        //         }
        //     } catch (error) {
        //         console.error("Failed to fetch content:", error);
        //     }
        // };
    
        // useEffect(() => {
        //     fetchInitialValue();
        //     console.log("Editor Value:", editorValue); // Log the parsed content
        // }, [data.videoId]); // Fetch new data when videoId changes
        


        // const deleteVideoId = () => {
        //     const confirmDelete = window.confirm("Are you sure you want to delete this video?");
            
        //     if (confirmDelete) {
        //         // Retrieve the existing object from local storage.
        //         const storedData = JSON.parse(localStorage.getItem('videos')) || {};
        
        //         // Check if the specific videoId exists in the object.
        //         if (storedData[data.videoId]) {
        //             // Delete the field corresponding to data.videoId.
        //             delete storedData[data.videoId];
        
        //             // Save the updated object back to local storage.
        //             localStorage.setItem('videos', JSON.stringify(storedData));
        //         }
        //     }
        // };




    const increaseCardSize = () => {
        // Toggle between the classes 'small', 'medium', and 'large'
        setCurrentCardSize((prevClass) => {
            if (prevClass === 'normal') return 'small';
            if (prevClass === 'small') return 'medium';
            if (prevClass === 'medium') return 'large';
            return 'large';
        });

        // Toggle between the classes 'small', and 'medium'
        setTabletSize((prevClass) => {
            if (prevClass === 'tablet-normal') return 'tablet-small';
            if (prevClass === 'tablet-small') return 'tablet-medium';
            return 'tablet-medium';
        });

        // Toggle between the classes 'normal', and 'full'
        setMobileSize((prevClass) => {
            if (prevClass === 'mobile-normal') return 'mobile-full';
            return 'mobile-full';
        });

        setWindowSize((prevClass) => {
            if (prevClass === 'window-sm') return 'window-m';
            if (prevClass === 'window-m') return 'window-l';
            if (prevClass === 'window-l') return 'window-xl';
            return 'window-xl';
        });
    }

    const decreaseCardSize = () => {
        // Toggle between the classes 'small', 'medium', and 'large'
        setCurrentCardSize((prevClass) => {
            if (prevClass === 'large') return 'medium';
            if (prevClass === 'medium') return 'small';
            if (prevClass === 'small') return 'normal';
            return 'normal';
        });

        // Toggle between the classes 'small', and 'medium'
        setTabletSize((prevClass) => {
            if (prevClass === 'tablet-medium') return 'tablet-small';
            if (prevClass === 'tablet-small') return 'tablet-normal';
            return 'tablet-normal';
        });

        // Toggle between the classes 'normal', and 'full'
        setMobileSize((prevClass) => {
            if (prevClass === 'mobile-full') return 'mobile-normal';
            return 'mobile-normal';
        });

        setWindowSize((prevClass) => {
            if (prevClass === 'window-xl') return 'window-l';
            if (prevClass === 'window-l') return 'window-m';
            if (prevClass === 'window-m') return 'window-sm';
            return 'window-sm';
        });
    }

    const handleCardSizeNormal = () => {
        setCurrentCardSize('normal')
    }

    const closeCard = () => {
        setCurrentCardSize('normal');
        setTabletSize('tablet-normal');
        setMobileSize('mobile-normal');
        setWindowOpen('window-closed');
        setWindowSize('window-m');
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
    }

    const openNewWindow = () => {
        setWindowOpen("window-open");
        setWindowSize('window-sm');
        setCurrentCardSize('normal');
        setTabletSize('tablet-normal');
        setMobileSize('mobile-normal');
    }

        // Call openNewWindow only if this card is active
        React.useEffect(() => {
            if (isActive) {
                openNewWindow();
                increaseCardSize();
                openShowNotes();
            } else {
                closeCard(); 
                setHorizontal();
                closeShowNotes();
            }
        }, [isActive]);

    const rotateCard = (prevClass) => {
        setVertical((prevClass) => {
            if (prevClass === 'horizontal') return 'vertical';
            if (prevClass === 'vertical') return 'horizontal';
        });
    }

    const setHorizontal = () => {
        setVertical('horizontal')
    }

    // Trigger the setAllHorizontal function when layout changes
    useEffect(() => {
        setVertical('horizontal');
        setMobileSize('mobile-normal');
        setCurrentCardSize('normal');
    }, [layout]); // Dependency array: runs whenever layout changes

    const toggleShowNotes= () => {
        setShowNotes(!showNotes);
    };

    const openShowNotes = () => {
        setShowNotes(true);
    }

    const closeShowNotes = () => {
        setShowNotes(false);
    }


    return (
        <>
        {/* <div className="d-flex" onClick={toggleShowNotes}>
            
            <span className="note-link">{data.name}</span>

            <button className="note-button" data-tooltip="Notes" onClick={toggleShowNotes}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-pen"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z"/></svg>
            </button>

            <button className="note-button" data-tooltip="Delete" onClick={deleteVideoId}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
            </button>
        </div> */}

         {showNotes &&
        <JoditPage user={user} data={data} showNotes={showNotes} toggleShowNotes={toggleShowNotes} closeCardWindowSidebar={closeCardWindowSidebar}/>
         }

        </>
    );
};

export default Note;