import { useState } from "react";
import "../styles/cards.css"
import Card from './Card';

function Cards({user, data, videoIds, startIndex, endIndex, deleteVideoId, fetchVideoIds, openCardWindowSidebar, closeCardWindowSidebar, activeCardIndex }) {

    const [layout, setLayout] = useState("grid");

    const toggleLayout = (prevClass) => {
        setLayout((prevClass) => {
            if (prevClass === 'full-width') return 'grid';
            // if (prevClass === 'grid') return 'masonry';
            return 'full-width';
        });
    }

    return (
        <>
            <div className={`card-wall pb-200 ${layout}`} >
            {videoIds && videoIds.length > 0 ? (
                videoIds.slice(startIndex, endIndex).map((video, index) => (
                    <Card 
                        user={user}
                        data={video} 
                        key={index} 
                        cardKey={index} 
                        layout={layout} 
                        deleteVideoId={deleteVideoId}
                        fetchVideoIds={fetchVideoIds}
                        openWindow={() => openCardWindowSidebar(index)}
                        closeCardWindowSidebar={closeCardWindowSidebar}
                        isActive={activeCardIndex === index}
                    />
                ))
            ) : null }
            </div>
        </>
    );
};

export default Cards;