import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "../styles/navbar.css"
import shopifyClient from '../shopify/shopifyClient';

import { database, auth, get, ref } from '../firebase/firebaseConfig';

import MyAccount from './MyAccount';
import Clock from './Clock';
import Stopwatch from './Stopwatch';


function Navbar({ user, setUser, userData, numberOfVideos, sidebarToggle, 
    fetchVideoIds, loadFolders, handleSignOut, isPricingModalOpen, setPricingModalOpen, 
    logInTime, setLogInTime, handlePricingModal, setAuthModalOpen, setIsSignUp }) {
    const [myAccountOpen, setMyAccountOpen] = useState(false);
    const toggleMyAccount = () => { setMyAccountOpen(!myAccountOpen); }

    // useEffect(() => {
    //     fetchVideoIds();
    //     loadFolders();

    //     if (userData && userData.userData) {
    //         setFreetrial(userData.userData.freetrial);
    //     }


    //     console.log("NAVBAR: USER.UID - ", user.uid);
    //     console.log("NAVBAR: USERDATA - ", userData);

    //     // console.log("Type of freetrial:", typeof freetrial); // Will log the type based on the state
    //     // console.log("Value of freetrial:", freetrial); // Will log the value based on the state

    // }, [user]);



    // const handleShopifyCheckout = async () => {
    //     try {
    //         const query = `
    //             mutation {
    //                 checkoutCreate(input: {
    //                 lineItems: [{
    //                     variantId: "gid://shopify/ProductVariant/43334485803148",
    //                     quantity: 1,
    //                     customAttributes: [
    //                     { key: "subscription", value: "true" }
    //                     ]
    //                 }]
    //                 }) {
    //                     checkout {
    //                         id
    //                         webUrl
    //                     }
    //                     userErrors {
    //                         field
    //                         message
    //                     }
    //                 }
    //             }
    //         `;

    //         const response = await fetch('https://notespresso.myshopify.com/api/2023-10/graphql.json', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'X-Shopify-Storefront-Access-Token': 'e7992b6e299ee5b283ef700ec30876be' // Replace with your actual token
    //             },
    //             body: JSON.stringify({ query })
    //         });

    //         const result = await response.json();

    //         console.log("API Response:", result);

    //         if (result.errors) {
    //             console.error("Error creating Shopify checkout:", result.errors);
    //             return;
    //         }

    //         const checkoutUrl = result.data.checkoutCreate.checkout.webUrl;
    //         window.location.href = checkoutUrl;
    //     } catch (error) {
    //         console.error("Error creating Shopify checkout:", error);
    //     }
    // };





    // if(userData.subscriptionInfo){
    // console.log(userData.subscriptionInfo.plan);
    // }

    function calculateDaysRemaining(timestamp) {
        const now = new Date(); // Current date and time
        const startDate = new Date(timestamp); // Convert timestamp to Date object
        const diffTime = now - startDate; // Difference in milliseconds
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        const daysRemaining = Math.max(0, 30 - diffDays); // Calculate remaining days, ensuring it doesn't go below 0
        return daysRemaining;
    }

    const remainingDays = calculateDaysRemaining(userData?.subscriptionInfo?.timestamp);
    const cancelDate = new Date(userData?.subscriptionInfo?.canceldate);
    const formattedCancelDate = cancelDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    // Output: "Oct 13"


    return (
        <>

            <nav className="navbar">
                <div className="d-flex flex-col">
                    {user.uid === "public" ?
                    <>
                        <div className="announcement-bar d-flex justify-center">
                            <div className="announcement-text">Create A Free Account to create your own workspace. </div>
                            
                            {/* <div className='one-month-trial desktop'>
                                Try for 1 month: $14.99
                            </div> */}
                            
                            {/* <div className='time-div desktop' style={{position: "absolute", right: "20px"}}> */}
                                {/* <Stopwatch/> */}
                                {/* <div className="" style={{rowGap: "5px"}}>
                                    <Clock/>
                                </div> */}
                            {/* </div> */}

                        </div>
                    </>
                        : user.uid ?
                            <>
                                <div className="announcement-bar">
                                    <div className="announcement-text">
                                        <div className='announcement-text-inner' style={{ rowGap: "2px" }}>
                                            {/* <div className='logged-in-as ml-10' >Logged in as user: {user.email}</div> */}

                                            {userData?.subscriptionInfo?.plan === "Free" ?
                                                <div className='space-used ml-10 '>Free Plan:
                                                    <progress id="file" value={numberOfVideos} max="15" style={{ width: "30px", height: "15px" }}> {numberOfVideos}% </progress>
                                                    Space used: {numberOfVideos}/15 notes
                                                </div>
                                                : null}

                                            {userData?.subscriptionInfo?.plan === "Basic" ?
                                                <div className='space-used ml-10 '>Basic Plan:
                                                    <progress id="file" value={30 - remainingDays} max="30" style={{ width: "30px", height: "15px" }}> {30 - remainingDays} </progress>
                                                    {remainingDays} Days Remaining
                                                </div>
                                                : null}

                                            {userData?.subscriptionInfo?.plan === "Subscription" && userData?.subscriptionInfo?.canceldate ? (
                                                <div className='space-used ml-10 '>Subscription Plan: Ends {formattedCancelDate}</div>
                                            ) : (
                                                userData?.subscriptionInfo?.plan === "Subscription" ? (
                                                    <div className='space-used ml-10 '>Subscription Plan</div>
                                                ) : null

                                            )}

                                            <div className='logged-in-as ml-10 desktop' >Logged in as user: {user.email ? user.email : user.uid}</div>

                                            {/* <div className='time-div desktop'> */}
                                                {/* <Stopwatch/> */}
                                                {/* <div className="" style={{rowGap: "5px"}}>
                                                    <Clock/> */}
                                                    {/* {user.uid !== "public" &&
                                                        <>
                                                            <div className="log-in-time">Logged in: {logInTime}</div>
                                                            <div className="log-in-time">Online: {onlineTime}</div>
                                                        </>
                                                    } */}
                                                {/* </div>
                                            </div> */}

                                        </div>

                                    </div>
                                </div>
                            </>
                            : null
                    }



                    <div className="navbar-inner">
                        <div className="logo-and-hamburger-wrap">
                            <Link to="/"><div className="nav-logo desktop">☕️ Note Workspace</div>
                                <div className="nav-subtitle desktop">Take Notes on YouTube Videos</div>
                            </Link>

                            {/* <Link to="/public" className="nav-link" >🗣 Public</Link>
                    <Link to="/Home" className="nav-link" >Home</Link> */}

                            <div className='hamburger mobile' onClick={sidebarToggle}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12" /><line x1="4" x2="20" y1="6" y2="6" /><line x1="4" x2="20" y1="18" y2="18" /></svg>
                            </div>
                        </div>

                        <div className='d-flex align-center'>

                            {/* <div className="mr-5" style={{ fontSize: "12px" }}>🔗 Links:</div> | */}
                            {userData?.subscriptionInfo?.plan !== "Subscription" ?
                                <div className="unlimited-workspace desktop" onClick={handlePricingModal}>Unlimited Workspace: $10/month</div>
                                : null}

                            {/* <a href="https://chatgpt.com/" target="_blank" className="external-link ml-10 mr-10 d-flex align-center">
                        <img src={chatgptIcon} alt="ChatGPT Icon" style={{ width: 'auto', height: '15px', marginRight: '3px' }} />
                        ChatGPT
                    </a>

                    <a href="https://youtube.com/" target="_blank" className="external-link ml-10 mr-10 d-flex align-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-youtube mr-5"><path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"/><path d="m10 15 5-3-5-3z"/></svg>
                        Find A Video
                    </a>  */}

                            {/* <div className='free-mode d-flex align-center mr-20' style={{fontSize: "14px"}} onClick={() => { toggleFreeMode(); setAuthModalOpen(true); setIsSignUp(true); }}>
                        <div className='mr-5'>Free Mode</div>
                        <div>
                            {freeMode ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="#007BFF" stroke="#007BFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-toggle-right free-mode-on"><rect width="28" height="14" x="2" y="6" rx="6" ry="6"/><circle cx="23" cy="13" r="5" fill="white" stroke="white"/></svg>
                            :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="#ccc" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-toggle-left"><rect width="28" height="14" x="2" y="6" rx="6" ry="6"/><circle cx="9" cy="13" r="5" fill="white" stroke="white"></circle></svg>
                            }
                        </div>
                    </div> */}


                            {/* <div className="mr-10"> | </div> */}
                            {/* <a href="https://chatgpt.com/" target="_blank" className="external-link ml-10 mr-10">ChatGPT</a> | */}

                            {/* PUBLIC USER */}
                            {user.uid === "public" ?
                                <>
                                    <button id="sign-up" onClick={() => { setAuthModalOpen(true); setIsSignUp(true); }}>Create A Free Workspace</button>
                                    <button id="log-in" onClick={() => { setAuthModalOpen(true); setIsSignUp(false); }}>Log In</button>
                                </>

                                // USER LOGGED IN
                                : user.uid ?
                                    <>

                                        {/* <div className='d-flex flex-col align-end' style={{rowGap: "2px"}}>
                                <div className='logged-in-as ml-10' >Logged in as user: {user.email}</div>

                                {userData?.subscriptionInfo?.plan === "Free" ?
                                    <div className='space-used ml-10 '>Free Plan:
                                        <progress id="file" value={numberOfVideos} max="15" style={{width: "30px", height: "15px"}}> {numberOfVideos}% </progress>
                                        Space used: {numberOfVideos}/15 notes
                                    </div>
                                : null}

                                {userData?.subscriptionInfo?.plan === "Basic" ?
                                    <div className='space-used ml-10 '>Basic Plan:
                                        <progress id="file" value={30 - remainingDays} max="30" style={{width: "30px", height: "15px"}}> {30 - remainingDays} </progress>
                                        {remainingDays} Days Remaining
                                    </div>
                                : null}

                                {userData?.subscriptionInfo?.plan === "Subscription" && userData?.subscriptionInfo?.canceldate ? (
                                    <div className='space-used ml-10 '>Subscription Plan: Ends {formattedCancelDate}</div>
                                ) : (
                                userData?.subscriptionInfo?.plan === "Subscription" ? (
                                    <div className='space-used ml-10 '>Subscription Plan</div>
                                ) : null 

                                )}
                            </div> */}

                                        {/* MY ACCOUNT */}
                                        <div className="myaccount-btn" style={{ position: "relatve", fontSize: "12px", marginLeft: "5px" }} onClick={toggleMyAccount}>
                                            My Account
                                        </div>
                                        <MyAccount user={user} setUser={setUser} userData={userData} toggleMyAccount={toggleMyAccount} handleSignOut={handleSignOut} closeMyAccount={() => setMyAccountOpen(false)} handlePricingModal={handlePricingModal} myAccountOpen={myAccountOpen} />

                                        <div id="log-out" onClick={handleSignOut}>Log Out</div>

                                    </>
                                    :


                                    <>
                                        <div className='logged-in-as ml-10' >Logged in as user: {user.email ? user.email : user.uid}</div>
                                        <button id="upgrade" className="mr-5" onClick={handlePricingModal}>Upgrade</button>
                                        <button id="log-out" onClick={handleSignOut}>Log Out</button>
                                    </>
                            }

                            {/* <div className='time-div'>
                        <Stopwatch/>
                        <div className="d-flex flex-col" style={{rowGap: "5px"}}>
                            <Clock/>
                            {user.uid !== "public" &&
                                <>
                                    <div className="log-in-time">Logged in: {logInTime}</div>
                                    <div className="log-in-time">Online: {onlineTime}</div>
                                </>
                            }
                        </div>
                    </div> */}

                        </div>


                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;