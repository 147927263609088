import React, { useState, useEffect, useRef, useMemo } from "react";
import { database, ref, update, get, set, push, remove } from '../firebase/firebaseConfig';
import Draggable from "react-draggable"; // Import Draggable
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import "../styles/card.css"
import "../styles/card-buttons.css"
import "../styles/card-resize.css"

import CardButtons from "./CardButtons"
import LinkFile from "./LinkFile";
import TextEditor from "../pages/TextEditor";
import JoditPage from "../pages/JoditPage";
import { Jodit } from "jodit-react";

function Card({ user, data, cardKey, layout, deleteVideoId, openWindow, fetchVideoIds, closeCardWindowSidebar, isActive }) {
    const [cardSize, setCurrentCardSize] = useState('normal');
    const [tabletSize, setTabletSize] = useState('tablet-normal');
    const [mobileSize, setMobileSize] = useState('mobile-normal');
    const [windowOpen, setWindowOpen] = useState('window-closed');
    const [windowSize, setWindowSize] = useState('window-sm');
    const [vertical, setVertical] = useState('horizontal');
    const [showNotes, setShowNotes] = useState(false);
    const [editorValue, setEditorValue] = useState();
    const iframeRef = useRef();
    const [editingVideoIndex, setEditingVideoIndex] = useState(null);
    const [newVideoName, setNewVideoName] = useState('');

    // Get the current page path
    const currentPath = window.location.pathname;

        // Save The Slate Text to Local Storage
        //         const contentRef = ref(database, `videos/${data.videoId}/notes`);

        // const fetchInitialValue = async () => {
        //     const contentRef = ref(database, `videos/${data.videoId}/notes`);

        //     try {
        //         const snapshot = await get(contentRef);

        //         if (snapshot.exists()) {
        //             const content = snapshot.val();
        //             // console.log("Fetched content:", content); // Log the fetched content
    
        //             const parsedContent = JSON.parse(content);
        //             // console.log("Parsed content:", parsedContent); // Log the parsed content
                    
        //             setEditorValue(parsedContent);

        //             const initialValue = parsedContent;
        //         }
        //     } catch (error) {
        //         console.error("Failed to fetch content:", error);
        //     }
        // };


        // const fetchInitialValue = async () => {
        //     const contentRef = ref(database, `videos/${data.videoId}/notes`);
        //     try {
        //         const snapshot = await get(contentRef);
        //         if (snapshot.exists()) {
        //             const content = snapshot.val();
        //             console.log("Fetched content:", content); // Log the fetched content
    
        //             const parsedContent = JSON.parse(content);
        //             console.log("Parsed content:", parsedContent); // Log the parsed content
    
        //             setEditorValue(content);
        //             console.log(parsedContent)
        //         }
        //     } catch (error) {
        //         console.error("Failed to fetch content:", error);
        //     }
        // };
    
        // useEffect(() => {
        //     fetchInitialValue();
        //     console.log("Editor Value:", editorValue); // Log the parsed content
        // }, [data.videoId]); // Fetch new data when videoId changes
        


        // const deleteVideoId = () => {
        //     const confirmDelete = window.confirm("Are you sure you want to delete this video?");
            
        //     if (confirmDelete) {
        //         // Retrieve the existing object from local storage.
        //         const storedData = JSON.parse(localStorage.getItem('videos')) || {};
        
        //         // Check if the specific videoId exists in the object.
        //         if (storedData[data.videoId]) {
        //             // Delete the field corresponding to data.videoId.
        //             delete storedData[data.videoId];
        
        //             // Save the updated object back to local storage.
        //             localStorage.setItem('videos', JSON.stringify(storedData));
        //         }
        //     }
        // };




    const increaseCardSize = () => {
        // Toggle between the classes 'small', 'medium', and 'large'
        setCurrentCardSize((prevClass) => {
            if (prevClass === 'normal') return 'small';
            if (prevClass === 'small') return 'medium';
            if (prevClass === 'medium') return 'large';
            return 'large';
        });

        // Toggle between the classes 'small', and 'medium'
        setTabletSize((prevClass) => {
            if (prevClass === 'tablet-normal') return 'tablet-small';
            if (prevClass === 'tablet-small') return 'tablet-medium';
            return 'tablet-medium';
        });

        // Toggle between the classes 'normal', and 'full'
        setMobileSize((prevClass) => {
            if (prevClass === 'mobile-normal') return 'mobile-full';
            return 'mobile-full';
        });

        setWindowSize((prevClass) => {
            if (prevClass === 'window-sm') return 'window-m';
            if (prevClass === 'window-m') return 'window-l';
            if (prevClass === 'window-l') return 'window-xl';
            return 'window-xl';
        });
    }

    const decreaseCardSize = () => {
        // Toggle between the classes 'small', 'medium', and 'large'
        setCurrentCardSize((prevClass) => {
            if (prevClass === 'large') return 'medium';
            if (prevClass === 'medium') return 'small';
            if (prevClass === 'small') return 'normal';
            return 'normal';
        });

        // Toggle between the classes 'small', and 'medium'
        setTabletSize((prevClass) => {
            if (prevClass === 'tablet-medium') return 'tablet-small';
            if (prevClass === 'tablet-small') return 'tablet-normal';
            return 'tablet-normal';
        });

        // Toggle between the classes 'normal', and 'full'
        setMobileSize((prevClass) => {
            if (prevClass === 'mobile-full') return 'mobile-normal';
            return 'mobile-normal';
        });

        setWindowSize((prevClass) => {
            if (prevClass === 'window-xl') return 'window-l';
            if (prevClass === 'window-l') return 'window-m';
            if (prevClass === 'window-m') return 'window-sm';
            return 'window-sm';
        });
    }

    const handleCardSizeNormal = () => {
        setCurrentCardSize('normal')
    }

    const closeCard = () => {
        setCurrentCardSize('normal');
        setTabletSize('tablet-normal');
        setMobileSize('mobile-normal');
        setWindowOpen('window-closed');
        setWindowSize('window-m');
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
    }

    const openNewWindow = () => {
        setWindowOpen("window-open");
        setWindowSize('window-sm');
        setCurrentCardSize('normal');
        setTabletSize('tablet-normal');
        setMobileSize('mobile-normal');
    }

        // Call openNewWindow only if this card is active
        React.useEffect(() => {
            if (isActive) {
                openNewWindow();
                increaseCardSize();
                openShowNotes();
            } else {
                closeCard(); 
                setHorizontal();
                closeShowNotes();
            }
        }, [isActive]);

    const rotateCard = (prevClass) => {
        setVertical((prevClass) => {
            if (prevClass === 'horizontal') return 'vertical';
            if (prevClass === 'vertical') return 'horizontal';
        });
    }

    const setHorizontal = () => {
        setVertical('horizontal')
    }

    // Trigger the setAllHorizontal function when layout changes
    useEffect(() => {
        setVertical('horizontal');
        setMobileSize('mobile-normal');
        setCurrentCardSize('normal');
    }, [layout]); // Dependency array: runs whenever layout changes

    const toggleShowNotes= () => {
        setShowNotes(!showNotes);
    };

    const openShowNotes = () => {
        setShowNotes(true);
    }

    const closeShowNotes = () => {
        setShowNotes(false);
    }


     // HANDLE CHANGE VIDEO NAME IN SIDEBAR
        const handleVideoEdit = (index, currentName) => {
        setEditingVideoIndex(index);
        setNewVideoName(currentName);
    };

    const handleVideoSave = async (index, oldName, vidId) => {
        const formattedNewName = newVideoName.trim();

        if (formattedNewName === oldName) {
            setEditingVideoIndex(null);
            return;
        }

        const videoId = vidId; // Assuming videoIds has an id property

        let videoFirebasePath;
        if (videoId) {
            if (currentPath === "/") {
                videoFirebasePath = `users/${user.uid}/home/videos/${videoId}`;
            } else {
                let pagePath;
                pagePath = decodeURIComponent(currentPath)
                videoFirebasePath = `users/${user.uid}/${pagePath}/videos/${videoId}`;
            }

            try {
                await update(ref(database, videoFirebasePath), { name: formattedNewName });
                console.log('Video name updated in Firebase');
                setEditingVideoIndex(null);
                fetchVideoIds();
            } catch (error) {
                console.error('Error updating video name in Firebase:', error);
            }
        }
    };

    const handleVideoKeyDown = (e, index, oldName, vidId) => {
        if (e.key === 'Enter') {
            handleVideoSave(index, oldName, vidId);
        }
    };
    // END - HANDLE CHANGE VIDEO NAME IN SIDEBAR

    // useEffect(() => {
    //     console.log("Card User: " + user.uid)
    // }, [user]); // Dependency array: runs whenever layout changes

    return (
        <>
        <div className={`card ${cardSize} ${tabletSize} ${mobileSize} ${windowOpen} ${windowSize} ${vertical} ${cardKey}`}>
            
            <Draggable handle=".card-handle">

            <div className='auto-resizable-iframe resize'>

                <CardButtons 
                    deleteVideoId={() => deleteVideoId(data.videoId)}
                    // deleteVideoId={deleteVideoId}

                    increaseCardSize={increaseCardSize}
                    decreaseCardSize={decreaseCardSize}
                    openNewWindow={openNewWindow}

                    setHorizontal={setHorizontal}
                    rotateCard={rotateCard}

                    closeCard={closeCard}
                    toggleShowNotes={toggleShowNotes}
                    openShowNotes={openShowNotes}
                    closeShowNotes={closeShowNotes}
                    openWindow={openWindow}
                    // openWindow={() => openCardWindow(index)}
                    closeCardWindowSidebar={closeCardWindowSidebar}
                />
                <div className='iframe-div'>

                    <div className='loading-text'
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '.9rem', fontWeight: "100", color: 'gray', fontFamily: "Edo" }}>
                        Loading...
                    </div>

                    <iframe
                        ref={iframeRef}
                        width="560"
                        height="315"
                        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
                                <a href=https://www.youtube.com/embed/${data.videoId}?enablejsapi=1>
                                    <img src=https://img.youtube.com/vi/${data.videoId}/hqdefault.jpg alt='Generic alt' style="width:102%">
                                    <span style='font-size:45px;text-shadow: 1px 1px 0 rgba(0,0,0,1), 1px 1px 5px rgba(0,0,0,1)'>
                                    ▸
                                    </span>
                                </a>`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    >
                    </iframe>
                </div>
                {/* <LinkFile videoId={data.videoId} onClose={() => setShowLink(false)} showLink={showLink} toggleShowLink={toggleShowLink} /> */}
            </div>
            </Draggable>
            <div className="text-left card-title">
                {editingVideoIndex === cardKey ?
                    <>
                        <div className='d-flex align-center list-item'>
                            <input type="text" value={newVideoName} onChange={(e) => setNewVideoName(e.target.value)} onKeyDown={(e) => handleVideoKeyDown(e, cardKey, data.name, data.videoId)} />
                            <div className='ml-5 sidebar-btn card-save' onClick={() => handleVideoSave(cardKey, data.name, data.videoId)} data-tooltip="Save"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg></div>
                        </div>
                    </>
                    :
                    <>
                        <div onClick={() => handleVideoEdit(cardKey, data.name, data.videoId)}>{data.name}</div>
                        <div className='rename-card-btn pencil' onClick={() => handleVideoEdit(cardKey, data.name, data.videoId)} data-tooltip="Rename"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg></div>
                    </>
                }
            </div>
            <div className="card-link"><a href={`https://www.youtube.com/watch?v=${data.videoId}`} target="_blank">{`https://www.youtube.com/watch?v=${data.videoId}`}</a></div>
        </div>
        
        {showNotes &&
        <JoditPage user={user} data={data} showNotes={showNotes} toggleShowNotes={toggleShowNotes} closeCardWindowSidebar={closeCardWindowSidebar}/>
        }

        </>
    );
};

export default Card;