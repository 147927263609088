import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'; // Updated for Firebase v9
import axios from 'axios';

const CheckoutSuccess = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    // useEffect(() => {
    //     const query = new URLSearchParams(location.search);
    //     const sessionId = query.get('session_id');
    
    //     console.log("Session ID from URL:", sessionId); // Log session ID to debug
    
    //     const handleCheckout = async () => {
    //         try {
    //             console.log("Making API call to backend...");
    //             const response = await axios.post(`${BACKEND_URL}/stripe-checkout-session`, {
    //                 sessionId: sessionId,
    //             });
                
    //             // Process the response as needed (e.g., display a success message)
    //             console.log("Checkout successful:", response.data);
    
    //             // Redirect to the dashboard or another page
    //             // navigate('/');
    //         } catch (error) {
    //             setError('An error occurred. Please try again.');
    //             console.error("Error in handleCheckout:", error); // Log error details
    //         } finally {
    //             setLoading(false);
    //         }
    //     };
    
    //         handleCheckout();
    //     }, [location.search, navigate]);
    

    return (
        <div>
            <h1>Checkout Sucess!</h1>
            <p>Your checkout was successful.</p>
            <Link to="/">Return Home</Link>
            {/* {loading && <p>Loading...</p>}
            {error && <p>{error}</p>} */}
        </div>
    );
};

export default CheckoutSuccess;
