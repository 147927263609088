import { useState } from "react";
import "../styles/cards.css"
import Note from './Note';

function Notes({user, data, showNotes, setShowNotes, videoIds, startIndex, endIndex, deleteVideoId, openCardWindowSidebar, closeCardWindowSidebar, activeCardIndex }) {

    const [layout, setLayout] = useState("grid");

    const toggleLayout = (prevClass) => {
        setLayout((prevClass) => {
            if (prevClass === 'full-width') return 'grid';
            // if (prevClass === 'grid') return 'masonry';
            return 'full-width';
        });
    }

    return (
        <>
            <div className={`page-notes card-wall d-flex flex-col ${layout}`}>
                {videoIds && videoIds.length > 0 ? (
                    videoIds.slice(startIndex, endIndex).map((video, index) => (
                        <Note user={user}
                            data={video} key={index} cardKey={index} 
                            layout={layout} 
                            deleteVideoId={deleteVideoId}
                            openWindow={() => openCardWindowSidebar(index)}
                            closeCardWindowSidebar={closeCardWindowSidebar}
                            isActive={activeCardIndex === index}
                        />
                    ))
                ) : null }
            </div>
        </>
    );
};

export default Notes;