import React, { useState } from 'react';
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig';
import "../styles/addavideo.css";

function AddANote({ addVideoId, onVideoAdded }) {
    const [inputValue, setInputValue] = useState('');
    // Get the current page path
    const currentPath = window.location.pathname;

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Function to extract video ID from a YouTube URL
    const extractVideoId = (url) => {
        const urlPatterns = [
            /youtube\.com\/watch\?v=([^\&\?\/]+)/,     // Matches URLs like 'youtube.com/watch?v=VIDEO_ID'
            /youtu\.be\/([^\&\?\/]+)/                 // Matches URLs like 'youtu.be/VIDEO_ID'
        ];
        
        for (const pattern of urlPatterns) {
            const match = url.match(pattern);
            if (match) {
                return match[1];
            }
        }
        
        return null;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const input = inputValue.trim();
        let noteName = input;
        let newVideoId = decodeURIComponent(input);

        // console.log("name: " + noteName);
        // console.log("newVideoId: " + newVideoId);

        // Check if the input is a URL and extract the video ID
        if (input.startsWith('http') || input.startsWith('www')) {
            newVideoId = extractVideoId(input);
        }

        if (newVideoId) {
            try {

                // Determine the Firebase path based on the URL
                let firebasePath;
                // Remove the leading slash from the path and use it as part of the Firebase path
                const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'
                firebasePath = `${pagePath}/videos`;

                // Fetch existing videos from the database
                const videoRef = ref(database, firebasePath);
                const snapshot = await get(videoRef);
                
                // Check if the path exists
                if (!snapshot.exists()) {
                    // Create the path if it doesn't exist
                    await set(videoRef, {});
                }

                // Fetch existing videos again after ensuring the path exists
                const updatedSnapshot = await get(videoRef);
                const videos = updatedSnapshot.exists() ? updatedSnapshot.val() : {};

                // Check if the videoId already exists
                const isDuplicate = Object.keys(videos).includes(newVideoId);

                if (isDuplicate) {
                    alert("This name already exists.");
                } else {
                    // Add the new video if it's not a duplicate
                    await addVideoId(newVideoId, noteName);
                    setInputValue('');
                    onVideoAdded(); // Notify parent component
                }
            } catch (error) {
                // console.log(error);
                alert('Note names cannot contain the following characters\n. # $ [ ]');
                // console.error('Failed to fetch videos:', error);
            }
        } else {
            alert("Invalid video URL or ID.");
        }
    };

    return (
        <div className="add-a-video">
            <h3>Add A Note</h3>
            <form onSubmit={handleSubmit} className="d-flex align-start flex-col add-a-video-form">
                <div className="d-flex">
                    <input type="text" placeholder="Enter title" value={inputValue} onChange={handleInputChange}/>
                    <button className="add-video-btn" type="submit" value="Add"> Add</button>
                </div>
            </form>
        </div>
    );
}

export default AddANote;
