import React, { useState } from 'react';
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig';
import "../styles/addavideo.css";
import chatgptIcon from '../assets/chatgpt-logo.svg'; // Adjust the path as needed
import youtubeIcon from '../assets/youtube-logo.png'; // Adjust the path as needed

function AddAVideo({ user, addVideoId, onVideoAdded }) {
    const [inputValue, setInputValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchChatGPTQuery, setSearchChatGPTQuery] = useState('');

    const [findAVideo, setFindAVideo] = useState(false);
    const [openChatGPT, setOpenChatGPT] = useState(false);
    // Get the current page path
    const currentPath = window.location.pathname;

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Function to extract video ID from a YouTube URL
    const extractVideoId = (url) => {
        const urlPatterns = [
            /youtube\.com\/watch\?v=([^\&\?\/]+)/,     // Matches URLs like 'youtube.com/watch?v=VIDEO_ID'
            /youtu\.be\/([^\&\?\/]+)/                 // Matches URLs like 'youtu.be/VIDEO_ID'
        ];
        
        for (const pattern of urlPatterns) {
            const match = url.match(pattern);
            if (match) {
                return match[1];
            }
        }
        
        return null;
    };

    // This Code checks if the video already exists, then if not, sends it to Home.js
    const handleSubmit = async (e) => {
        e.preventDefault();
        const input = inputValue.trim();
        let newVideoId = input;

        // Check if the input is a URL and extract the video ID
        if (input.startsWith('http') || input.startsWith('www')) {
            newVideoId = extractVideoId(input);
        }

        if (newVideoId) {
            try {

                // Determine the Firebase path based on the URL
                let firebasePath;
                const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'
                if (currentPath === '/') {
                    firebasePath = `users/${user.uid}/home/videos`;
                } else {
                    // Remove the leading slash from the path and use it as part of the Firebase path
                    firebasePath = `users/${user.uid}/${pagePath}/videos`;
                }

                // Fetch existing videos from the database
                const videoRef = ref(database, firebasePath);
                const snapshot = await get(videoRef);
                // Check if the path exists
                if (!snapshot.exists()) {
                    // Create the path if it doesn't exist
                    await set(videoRef, {});
                }

                // Fetch existing videos again after ensuring the path exists
                const updatedSnapshot = await get(videoRef);
                const videos = updatedSnapshot.exists() ? updatedSnapshot.val() : {};

                // Check if the videoId already exists
                const isDuplicate = Object.keys(videos).includes(newVideoId);

                if (isDuplicate) {
                    alert("This video already exists.");
                } else {
                    // Add the new video if it's not a duplicate
                    await addVideoId(newVideoId);
                    setInputValue('');
                    onVideoAdded(); // Notify parent component
                }
            } catch (error) {
                console.error('Failed to fetch videos:', error);
            }
        } else {
            alert("Invalid video URL or ID.");
        }
    };

    const handleSearchYouTubeQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const toggleFindAVideo = () => {
        setFindAVideo(!findAVideo);
    }

    const toggleOpenChatGPT = () => {
        setOpenChatGPT(!openChatGPT);
    }

    const handleSearchYouTubeSubmit = (e) => {
        e.preventDefault();
        const query = encodeURIComponent(searchQuery.trim());
        if (query) {
            window.open(`https://www.youtube.com/results?search_query=${query}`, '_blank');
            setSearchQuery("")
        } else {
            alert("Please enter a search query.");
        }
    };

    const handleSearchChatGPTQueryChange = (e) => {
        setSearchChatGPTQuery(e.target.value);
        
        // Auto-expanding logic
        const textarea = e.target;
        textarea.style.height = 'auto'; // Reset the height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scroll height
    };
    
    const handleSearchChatGPTSubmit = (e) => {
        e.preventDefault();
        const query = encodeURIComponent(searchChatGPTQuery.trim());
        if (query) {
            // Opens ChatGPT in a new tab
            window.open(`https://chat.openai.com/?q=${query}`, '_blank', 'noopener,noreferrer');
            setSearchChatGPTQuery("");
        } else {
            alert("Please enter a search query.");
        }
    };

    return (
        <>
        <h3>Add A Video</h3>
        <div className="add-a-video">

            <form onSubmit={handleSubmit} className='d-flex align-start flex-col add-a-video-form'>
                <div className="d-flex">
                    <input type="text" placeholder="Paste a YouTube URL or Video ID" value={inputValue} onChange={handleInputChange}/>
                    <button className="add-video-btn" type="submit" value="Add"> Add</button>
                </div>
                    {/* <input type="text" placeholder="Add a title (Optional)" value={inputValue} onChange={handleInputChange} style={{height: "25px", width: "350px"}}/> */}
            </form>

            <div className="youtube-link" onClick={toggleFindAVideo}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg> */}
            <img src={youtubeIcon} alt="YouTube Icon" style={{ width: 'auto', height: '12px', marginRight: '3px' }} />
                <span className="mx-1 geist-regular">Find A Video</span>
                {findAVideo ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6"/></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>
                }
            </div>

            {findAVideo &&
            <form onSubmit={handleSearchYouTubeSubmit} className='youtube-search-form'>
                <div className='youtube-search-title'>Search YouTube</div>
                <div className='d-flex youtube-search-form-flex'>
                    <input
                        type="text"
                        placeholder="Enter search text"
                        value={searchQuery}
                        onChange={handleSearchYouTubeQueryChange}
                    />
                    <button className="search-btn" type="submit" value="Search">Search</button>
                </div>
            </form>
            }

            <div className="youtube-link" onClick={toggleOpenChatGPT}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg> */}
            <img src={chatgptIcon} alt="ChatGPT Icon" style={{ width: 'auto', height: '15px', marginRight: '3px' }} />
                <span className="mx-1 geist-regular">Search ChatGPT</span>
                {openChatGPT ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6"/></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>
                }
            </div>

            {openChatGPT &&
            <form onSubmit={handleSearchChatGPTSubmit} className='youtube-search-form chatgpt-search-form'>
                <div className='youtube-search-title'>
                    Search ChatGPT</div>
                <div className='d-flex youtube-search-form-flex'>
                    <textarea
                        type="text"
                        placeholder="Enter search text"
                        value={searchChatGPTQuery}
                        onChange={handleSearchChatGPTQueryChange}
                        rows={1} // Starting row height
                        className='auto-expand'
                    />
                    <button className="search-btn" type="submit" value="Search">Search</button>
                </div>
                <div className="search-disclaimer">You will be redirected to ChatGPT. Please wait a few seconds for your search query to load.</div>
            </form>
            }

            {/* <div className="youtube-link"><a href="https://www.youtube.com" target="_blank" style={{color: "black"}}>Go to <span style={{color: "blue"}}>YouTube.com</span></a></div> */}
        </div>
        </>
    );
}

export default AddAVideo;
