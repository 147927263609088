import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const DeleteSuccess = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div>
            <h1>Account Deleted Succesfujlly</h1>
            <p>Your account and data was deleted.</p>
            <Link to="/">Return to Homepage</Link>
        </div>
    );
};

export default DeleteSuccess;
