import React, { useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import "../styles/jodit.css"
import Draggable from "react-draggable"; // Import Draggable
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig'

function Jodit ({ user, data, showNotes, toggleShowNotes, closeCardWindowSidebar }){

    // Get the current page path
    const currentPath = window.location.pathname;

    const [content, setContent] = useState('');

    // Load content from JSON file
    useEffect(() => {
        // Set Default Videos and Data
        setContent(data.notes || '');
    }, [data.notes]);

    // Load content from local storage when the component mounts
    useEffect(() => {
        // const savedContent = localStorage.getItem('youtubeSaver');
        // if (savedContent) {
        // setContent(savedContent);
        // }
        // Set Default Videos and Data
        // console.log(data);
        // setContent(data.content);
    }, []);

    // Save content to local storage whenever it changes
    const handleContentChange = async (newContent) => {

      let videoId = data.videoId;

      try {

        // Determine the Firebase path based on the URL
        let firebasePath;
        if (currentPath === '/') {
            firebasePath = `users/${user.uid}/home/videos/${videoId}/notes`;
        } 
        else if(currentPath === '/notes') {
          firebasePath = `users/${user.uid}/notes/videos/${videoId}/notes`;
        }
        else {
            // Remove the leading slash from the path and use it as part of the Firebase path
            const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'
            firebasePath = `users/${user.uid}/${pagePath}/videos/${videoId}/notes`;
            // console.log(firebasePath);
        }

        // Fetch existing videos from the database
        const videoRef = ref(database, firebasePath);
        const snapshot = await get(videoRef);

        // Add the new video to Firebase with videoId as the key
          await set(videoRef, newContent);
        } catch (error) {
          console.error('Failed to add notes:', error);
        }

  };


      // Customize toolbar buttons
  const config = { // all options from https://xdsoft.net/jodit/doc/
    readonly: false,
    minwidth: 400,
    width: 610, // Set the width (can be in pixels or percentage)
    height: "90vh",  // Set the height (in pixels)
    toolbarSticky: false,  // Disable sticky toolbar
    colors: {
      greyscale:  ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
      palette:    ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
      full: [
          '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
          '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
          '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
          '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
          '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
          '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
      ]
  },
  colorPickerDefaultTab: 'background',
    buttons: [
      // 'source',
      'bold',
      'strikethrough',
      'underline',
      'italic', '|',
      'ul',
      'ol', '|',
      'outdent', 'indent',  '|',
      'paragraph',
      'font',
      'fontsize',
      'brush',
      'image',
      '|',


      'link', '|',
      'align', 'undo', 'redo', '|',
      'hr',
      '|',
      'print',
    ], // Only the buttons you want to show
    defaultMode: '1', // Set the default mode (1 = P, 2 = DIV, 3 = BR)
    defaultActionOnPaste: 'insert_only_text', // Paste text only by default
    enter: 'P', // Default action when pressing Enter (P tag)
    defaultTag: 'p', // Default tag for text
    direction: 'ltr', // Left-to-right text direction
    toolbarAdaptive: false, // Keep toolbar responsive
    buttonsXS: [
      'align', 'undo', 'redo', 'bold', 'italic', 'underline', 'strikethrough'
    ],
    allowResizeX: true,
    allowResizeY: true,
    toolbarButtonSize: 'middle', // Adjust toolbar button size
    align: 'left',  // Ensure default alignment is left
    defaultActionOnPaste: 'keep',  // Automatically keep as HTML
    askBeforePasteHTML: false,  // Disable the paste as HTML modal
    
    // Bind Tab and Shift+Tab for indent/outdent
    commandToHotkeys: {
      indent: ['tab'],            // Pressing Tab to indent
      outdent: ['shift+tab'],      // Pressing Shift+Tab to outdent
      strikethrough: ['command+shift+s', 'ctrl+shift+s'] // Add both Command+Shift+S and Ctrl+Shift+S
    }

  };

    return(
        <>

  <Draggable handle=".handle">
    <div className={`editor-container ${showNotes ? "d-flex" : "d-none"}`}>
        <div className="handle editor-handle">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip"><circle cx="12" cy="5" r="1"/><circle cx="19" cy="5" r="1"/><circle cx="5" cy="5" r="1"/><circle cx="12" cy="12" r="1"/><circle cx="19" cy="12" r="1"/><circle cx="5" cy="12" r="1"/><circle cx="12" cy="19" r="1"/><circle cx="19" cy="19" r="1"/><circle cx="5" cy="19" r="1"/></svg>
        </div>
        <div className="close-editor" onClick={ () => {toggleShowNotes(); closeCardWindowSidebar();}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
        </div> 
            <JoditEditor
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={handleContentChange} // Save content on change
            />
        {/* <div className="handle">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip"><circle cx="12" cy="5" r="1"/><circle cx="19" cy="5" r="1"/><circle cx="5" cy="5" r="1"/><circle cx="12" cy="12" r="1"/><circle cx="19" cy="12" r="1"/><circle cx="5" cy="12" r="1"/><circle cx="12" cy="19" r="1"/><circle cx="19" cy="19" r="1"/><circle cx="5" cy="19" r="1"/></svg>
        </div> */}
          </div>

    </Draggable>

        </>
    )
}

export default Jodit;