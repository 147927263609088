import React, { useState, useEffect } from 'react';
import { database, ref, get, set, update, push, remove } from './firebase/firebaseConfig';
import { auth } from './firebase/firebaseConfig';
import { signOut, onAuthStateChanged, getRedirectResult } from 'firebase/auth';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import './styles/globals.css'
import './styles/fonts.css'
// import 'geist/styles/index.css';  // Import Geist's CSS


import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import Public from './pages/Public';
import Page from './pages/Page';
import PublicPage from './pages/PublicPage';
import PageNotes from './pages/PageNotes';
import WatchLater from './pages/WatchLater';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CheckoutCancel from './pages/CheckoutCancel';
import DeleteSuccess from './pages/DeleteSuccess';

import AuthModal from './components/AuthModal';
import PricingModal from './components/PricingModal';

import TextEditor from './pages/TextEditor';
import JoditPage from './pages/JoditPage';

import publicData from './data/public.json';

// Your backend URL where the `/create-checkout-session` endpoint is hosted
// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// const BACKEND_URL = "http://localhost:4242/api"
const BACKEND_URL = "https://www.noteworkspace.com/api"
const stripePromise = loadStripe('pk_test_uRNBzI5T0fPQP18tnGh34AA9'); // Your Stripe publishable key



function App() {
  return (
    <Router>
      <div className="App">
        <div className='d-flex' style={{ height: "100vh", width: "100%" }}>
          <PageManager />
        </div>
      </div>
    </Router>
  );
}

function PrivateRoute({ user, children }) {
  return user ? children : <Navigate to="/public" />;
}

function PageManager() {
  const [folders, setFolders] = useState([]);
  const [videoIds, setVideoIds] = useState([]);
  const [pages, setPages] = useState([]);

  const [foldersPublic, setFoldersPublic] = useState([]);
  const [videoIdsPublic, setVideoIdsPublic] = useState([]);
  const [pagesPublic, setPagesPublic] = useState([]);

  // Initialize state and function for handling active card
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [user, setUser] = useState("");
  const [userData, setUserData] = useState("");
  const [numberOfVideos, setNumberOfVideos] = useState("")
  const [freeVideoLimit, setFreeVideoLimit] = useState("");
  const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
  const [logInTime, setLogInTime] = useState("");

  const [loading, setLoading] = useState(true);

  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const closePricingModal = () => { setPricingModalOpen(false); }
  const handlePricingModal = () => { setPricingModalOpen(true); }
  const [isPricingModalOpen, setPricingModalOpen] = useState(false);

  // Get the current page path
  const currentPath = window.location.pathname;


  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  }




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const handleUserAuth = async () => {

        if (user) {
          setUser(user);
          try{
            let firebasePath;
              firebasePath = `users/${user.uid}/subscriptionInfo`;
              const userRef = ref(database, firebasePath);
              const snapshot = await get(userRef);

              // If subscriptionInfo does not exist, set default values
              if (!snapshot.exists()) {
                await update(userRef, {
                  email: user.email,
                  plan: 'Free'
                });
                // Then Refetch user data for new user to update the state with new subscriptionInfo
                await getUserData();
                await countKeysInVideosFolder(user.uid);
              }else{
                await getUserData();
                await countKeysInVideosFolder(user.uid);
                await checkPlan();
            }

            } catch (error) {
              console.error("Error accessing or updating subscriptionInfo:", error);
            }

            
        } else {
          setUser({uid: "public"})
          setFreeVideoLimit("public");
        }
      };
  
      handleUserAuth(); // Call the async function
    });
  
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [auth, navigate]);



//   useEffect(() => {
//     console.log('Checking redirect result');
//     const handleRedirect = async () => {
//         try {
//             console.log('Attempting to get redirect result...');
//             const result = await getRedirectResult(auth);
//             if (result) {
//                 console.log("RESULT:", result);
//                 const user = result.user;
//                 console.log('User signed in:', user);
//                 navigate('/notes');
//             } else {
//                 console.log('No result found.');
//             }
//         } catch (error) {
//             console.error('Error handling redirect:', error.message);
//             setErrorMessage('Failed to handle redirect.');
//         }
//     };

//     handleRedirect();
// }, [auth, navigate]);



  
  


  const handleSignOut = async () => {
    try {
        await signOut(auth);
        setUser({uid: "public"})
        fetchVideoIds()
        navigate('/')
        setFreeVideoLimit("public");
    } catch (error) {
        console.error('Sign-out error:', error.message);
    }
};




        // GET USER DATA FROM FIREBASE
        const getUserData = async () => {
          if (!user?.uid) return;
        
          try {
            const firebasePath = `users/${user.uid}`;
            const dbRef = ref(database, firebasePath);
            const snapshot = await get(dbRef);
        
            if (snapshot.exists()) {
              const userData = snapshot.val();
              setUserData(userData); // Update userData state
              // console.log("User data updated: ", userData);
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        };






  const countKeysInVideosFolder = async (uid) => {
    try {
      const firebasePath = `users/${uid}`;
      const videoRef = ref(database, firebasePath);
      const snapshot = await get(videoRef);
      const userData = snapshot.val();
  
      if (userData) {
        let keyCount = 0;
  
        const countKeys = (data, inNotesFolder = false) => {
          if (typeof data === 'object' && data !== null) {
            // If the folder is named 'videos' and not in 'notes', count its keys
            // if (data.hasOwnProperty('videos') && !inNotesFolder) {
              if (data.hasOwnProperty('videos')) {
              keyCount += Object.keys(data.videos).length;
            }
            
            // Recursively search through all properties
            for (const key in data) {
              if (data.hasOwnProperty(key) && typeof data[key] === 'object') {
                // Check if the current folder is 'notes'
                const isNotesFolder = key === 'notes';
                countKeys(data[key], isNotesFolder);
              }
            }
          }
        };
  
        countKeys(userData);
        setNumberOfVideos(keyCount);
  
        // Store the key count in Firebase under the user's profile
        const keyCountRef = ref(database, `users/${uid}/subscriptionInfo/videosKeyCount`);
        await set(keyCountRef, keyCount);
        // console.log(`Number of keys in 'videos' folders (excluding 'notes'): ${keyCount}`);
        // console.log(user.providerData);
        return keyCount;
      } else {
        // console.log("No user data found.");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  
  

  const checkPlan = async () => {
    let firebasePath;
    firebasePath = `users/${user.uid}/subscriptionInfo`;
    const userRef = ref(database, firebasePath);
    const snapshot = await get(userRef);

    // // Fetch existing subscription info
    const subscriptionInfo = snapshot.val();
    // Check if subscriptionInfo exists and has a plan
    if (!subscriptionInfo || !subscriptionInfo.plan) {
      // console.error("Subscription info or plan not found.");
      return;  // Stop further execution if there's no plan
    }

    const plan = subscriptionInfo.plan;
    // Get the keys in the videos folder
    const keyCount = subscriptionInfo.videosKeyCount

      // Conditional logic based on the user's plan
      if (plan === 'Free' && keyCount >= 15) {
        // Rmove Add A Video from Home, Pages, and Watch Later
          setFreeVideoLimit("over");
          // console.log(freeVideoLimit);
      }
      else if (plan === 'Free' && keyCount < 15) {
        setFreeVideoLimit("under");
        // console.log(freeVideoLimit);
      }
      
      
      else if (plan === 'Basic') {
        // console.log("User has a Basic plan");
        setFreeVideoLimit("under");
        // Handle Basic plan expiration after 31 days
        const timestamp = subscriptionInfo.timestamp;
        if (timestamp) {
          const timestampDate = new Date(timestamp);
          const currentDate = new Date();

          // Calculate the difference in days
          const timeDifference = currentDate - timestampDate;
          const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days
          if (daysDifference >= 31) {
            // Update the user's plan to "Free" and reset the timestamp
            await update(userRef, {
              plan: 'Free',
              timestamp: ''
            });
            console.log("User's Basic plan has expired. Changed to Free plan.");
          }
        }

      } 
      
      
      else if (plan === 'Subscription') {
        setFreeVideoLimit("under");
        // Handle Subscription plan expiration when cancel date is before or equal to today's date
        const canceldate = subscriptionInfo.canceldate;
        
        if (canceldate) {
          const cancelDateObj = new Date(canceldate);
          const currentDate = new Date();

          // Check if the cancel date is before or equal to the current date
          if (cancelDateObj < currentDate) {
            // Update the user's plan to "Free" and reset the timestamp
            await update(userRef, {
              canceldate: '',
              plan: 'Free',
              subscriptionId: '',
              timestamp: ''
            });
            // console.log("User's Subscription plan has expired. Changed to Free plan.");
          } else {
            // console.log("Subscription is still active. Cancel date is in the future.");
          }
        } else {
          // console.log("No cancel date found in subscriptionInfo.");
        }
      }
      else{}
  }



  const handleStripeCheckout = async (priceId, mode, planType, userId) => {
    const stripe = await stripePromise;

    // console.log(`Fetching from: ${BACKEND_URL}/create-checkout-session`);

    try {
        const response = await fetch(`${BACKEND_URL}/create-checkout-session/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                priceId,
                mode,
                metadata: {
                    planType,
                    userId // Pass the unique userId
                }
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const sessionId = await response.json();

        const result = await stripe.redirectToCheckout({
            sessionId: sessionId.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    } catch (error) {
        console.error("Error creating Stripe checkout:", error);
    }
};



    // LOAD ALL VIDEOS: HOME and PAGE
    const fetchVideoIds = async () => {

      let firebasePath;
      const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'

      if(currentPath === "/"){
        firebasePath = `users/${user.uid}/home/videos`;
      }else{
        firebasePath = `users/${user.uid}/${pagePath}/videos`
      }

        const videoRef = ref(database, firebasePath);
        const snapshot = await get(videoRef);

        if (snapshot.exists()) {
            const videos = snapshot.val();

            // Convert the object to an array and sort by the `order` field
            const sortedVideoIds = Object.values(videos).sort((a, b) => a.order - b.order);
            setVideoIds(sortedVideoIds);
            countKeysInVideosFolder(user.uid)
        } else {
            setVideoIds([]);
            countKeysInVideosFolder(user.uid)
        }
    };

    // LOAD ALL VIDEOS: PUBLIC
    const fetchVideoIdsPublic = async () => {

      let firebasePath;
      const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'public/pages/new-page-1'
      // console.log(pagePath);
      if(currentPath === "/"){
        firebasePath = `users/public/videos`;
      }else{
        firebasePath = `users/public/${pagePath}/videos`
      }

        const videoRef = ref(database, firebasePath);
        const snapshot = await get(videoRef);

        if (snapshot.exists()) {
            const videos = snapshot.val();

            // Convert the object to an array and sort by the `order` field
            const sortedVideoIds = Object.values(videos).sort((a, b) => a.order - b.order);
            setVideoIdsPublic(sortedVideoIds);
        } else {
            setVideoIdsPublic([]);
        }
    };




    // LOAD FOLDERS
    const loadFolders = async () => {
      const firebasePathFolders = `users/${user.uid}/pages`;
      const videoRefAll = ref(database, firebasePathFolders);
      const snapshotAll = await get(videoRefAll);
      const folders = snapshotAll.val();

      if (folders) {
          // Sort the folders by their 'order' property
          const foldersSorted = Object.entries(folders)
              .sort(([, a], [, b]) => a.order - b.order)  // Sort by the 'order' property
              .map(([key, value]) => ({
                key,  // folder key
                name: value.name,  // folder name
                // url: value.url  // folder name
              }));  // Map to an array of objects with 'key' and 'name'
        

          setFolders(foldersSorted);
      } else {
          // console.warn('No folders found at', firebasePathFolders);
          setFolders([]); // Set to an empty array or handle the empty case appropriately
      }
    };





    // LOAD FOLDERS PUBLIC
    const loadFoldersPublic = async () => {
      const firebasePathFolders = `users/public/pages`;
      const videoRefAll = ref(database, firebasePathFolders);
      const snapshotAll = await get(videoRefAll);
      const folders = snapshotAll.val();

      if (folders) {
          // Sort the folders by their 'order' property
          const foldersSorted = Object.entries(folders)
              .sort(([, a], [, b]) => a.order - b.order)  // Sort by the 'order' property
              .map(([key, value]) => ({
                key,  // folder key
                name: value.name,  // folder name
                // url: value.url  // folder name
              }));  // Map to an array of objects with 'key' and 'name'
        

          setFoldersPublic(foldersSorted);
      } else {
          console.warn('No folders found at', firebasePathFolders);
          setFoldersPublic([]); // Set to an empty array or handle the empty case appropriately
      }
    };




// CREATE NEW PAGE (FOLDER)
const createNewPage = async () => {
  let pageNumber = 1;
  let newPageName;
  let formattedPageName;
  let urlName;
  let firebasePath;
  let pageExists = true;
  let lastOrder = 0;

    // Retrieve all existing pages to determine the last order
    const pagesRef = ref(database, `users/${user.uid}/pages`);
    const pagesSnapshot = await get(pagesRef);

    if (pagesSnapshot.exists()) {
      const pagesData = pagesSnapshot.val();
      const orders = Object.values(pagesData).map(page => page.order).filter(order => typeof order === 'number');
      lastOrder = Math.max(...orders, 0); // Get the highest order value, or 0 if none exist
    }

  // Loop until a unique page name is found
  while (pageExists) {
    newPageName = `Page ${pageNumber}`;
    formattedPageName = newPageName.replace(/\s+/g, ''); // Replaces spaces with nothing
    urlName = newPageName.replace(/\s+/g, '-');

    // Determine the Firebase path based on the URL
    firebasePath = `users/${user.uid}/pages/${urlName}`;
    const videoRef = ref(database, firebasePath);

    // Check if the folder already exists in Firebase
    const snapshot = await get(videoRef);

    if (!snapshot.exists()) {
      pageExists = false; // If it doesn't exist, exit the loop
    } else {
      pageNumber++; // If it exists, increment the page number and try again
    }
  }

    // Create the new page object with 'order' and 'name' keys
    const newPageData = {
      order: lastOrder + 1,  // Increment the last order value by 1
      name: newPageName,
      // url: newPageName.toLowerCase().replace(/\s+/g, '-'),
      videos: "",
    };

  // Add an empty object to Firebase at the specified path
  await set(ref(database, firebasePath), newPageData)
    .then((response) => {
      // console.log('New page created with an empty object in Firebase:', response);
    })
    .catch((error) => {
      // console.error('Error saving new page to Firebase:', error);
    });

  setPages([...pages, newPageName]);
  navigate(`/pages/${urlName}`);
  loadFolders();
};
// END CREATE NEW PAGE (FOLDER)



// UPDATE PAGE NAME
  const updatePageName = (index, newName) => {
    const updatedPages = pages.map((page, i) =>
      i === index ? newName : page
    );
    setPages(updatedPages);
  };
// END UPDATE PAGE NAME



// CREATE NEW PAGE PUBLIC (FOLDER)
const createNewPagePublic = async () => {
  let pageNumber = 1;
  let newPageName;
  let formattedPageName;
  let urlName;
  let firebasePath;
  let pageExists = true;
  let lastOrder = 0;

    // Retrieve all existing pages to determine the last order
    const pagesRef = ref(database, `public/pages`);
    const pagesSnapshot = await get(pagesRef);

    if (pagesSnapshot.exists()) {
      const pagesData = pagesSnapshot.val();
      const orders = Object.values(pagesData).map(page => page.order).filter(order => typeof order === 'number');
      lastOrder = Math.max(...orders, 0); // Get the highest order value, or 0 if none exist
    }

  // Loop until a unique page name is found
  while (pageExists) {
    newPageName = `Page ${pageNumber}`;
    formattedPageName = newPageName.replace(/\s+/g, ''); // Replaces spaces with nothing
    urlName = newPageName.replace(/\s+/g, '-');

    // Determine the Firebase path based on the URL
    firebasePath = `public/pages/${urlName}`;
    const videoRef = ref(database, firebasePath);

    // Check if the folder already exists in Firebase
    const snapshot = await get(videoRef);

    if (!snapshot.exists()) {
      pageExists = false; // If it doesn't exist, exit the loop
    } else {
      pageNumber++; // If it exists, increment the page number and try again
    }
  }

    // Create the new page object with 'order' and 'name' keys
    const newPageData = {
      order: lastOrder + 1,  // Increment the last order value by 1
      name: newPageName,
      // url: newPageName.toLowerCase().replace(/\s+/g, '-'),
      videos: "",
    };

  // Add an empty object to Firebase at the specified path
  await set(ref(database, firebasePath), newPageData)
    .then((response) => {
      // console.log('New page created with an empty object in Firebase:', response);
    })
    .catch((error) => {
      // console.error('Error saving new page to Firebase:', error);
    });

  setPagesPublic([...pages, newPageName]);
  navigate(`public/pages/${urlName}`);
  loadFoldersPublic();
};
// END CREATE NEW PAGE PUBLIC (FOLDER)



// UPDATE PAGE NAME
  const updatePageNamePublic = (index, newName) => {
    const updatedPages = pages.map((page, i) =>
      i === index ? newName : page
    );
    setPagesPublic(updatedPages);
  };
// END UPDATE PAGE NAME



// OPEN CARD FROM SIDEBAR
  const openCardWindowSidebar = (index) => {
    // Toggle logic
    setActiveCardIndex((prevIndex) => { return prevIndex === index ? null : index
    }); 
      // setActiveCardIndex(index);
  };

  const closeCardWindowSidebar = (index) => {
    setActiveCardIndex(null); // Toggle logic
  };

// TOGGLE SIDEBAR
  const sidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  }

  const sidebarClose = () => {
    setSidebarOpen(false);
  }

//   useEffect(() => {
//     userData();
//     fetchVideoIds();
//     loadFolders();
//     // loadFoldersPublic();
// }, []);


useEffect(() => {
  fetchVideoIds();
  loadFolders();
  // loadFoldersPublic();
  getUserData();
  countKeysInVideosFolder(user.uid);
  checkPlan();
}, [user]);

useEffect(() => {
  if (user && user.uid) {
    getUserData();  // Only fetch data if user is available
    countKeysInVideosFolder(user.uid);  // Count keys in videos folder
    checkPlan();
  }
}, [user]);  // Run this effect when `user` changes


  return (
    <>
    <div className='dlfex flex-col' style={{ height: "100vh", width: "100%" }}>

      <Navbar user={user} setUser={setUser} userData={userData} numberOfVideos={numberOfVideos} 
      sidebarToggle={sidebarToggle} fetchVideoIds={fetchVideoIds} 
      loadFolders={loadFolders} handleSignOut={handleSignOut}
      handlePricingModal={handlePricingModal}
      isPricingModalOpen={isPricingModalOpen}
      setPricingModalOpen={setPricingModalOpen}
      logInTime={logInTime}
      setLogInTime={setLogInTime}

      setAuthModalOpen={setAuthModalOpen}
      setIsSignUp={setIsSignUp}

      />

      {isAuthModalOpen && 
          <AuthModal 
              isSignUp={isSignUp} 
              setIsSignUp={setIsSignUp} 
              closeAuthModal={() => setAuthModalOpen(false)} 
              setLogInTime={setLogInTime} 
          />
      }

      {isPricingModalOpen && 
          <PricingModal 
              user={user} 
              userData={userData} 
              handleStripeCheckout={handleStripeCheckout} 
              setPricingModalOpen={setPricingModalOpen} 
              closePricingModal={closePricingModal} 
          />
      }

      
      <div className="d-flex no-nav" style={{ height: "100vh", width: "100%"}}>
      <Sidebar
            user={user}
            videoIds={videoIds} 
            videoIdsPublic={videoIdsPublic} 
            fetchVideoIds={async () => { fetchVideoIds() }}
            fetchVideoIdsPublic={async () => { fetchVideoIdsPublic() }}

            folders={folders}
            foldersPublic={foldersPublic}
            loadFolders={async () => { loadFolders() }}
            loadFoldersPublic={async () => { loadFoldersPublic() }}

            pages={pages}
            setPages={setPages}

            createNewPage={async () => { createNewPage() }} 
            updatePageName={(index, newName) => { updatePageName() }} 
            
            activeCardIndex={activeCardIndex} 
            openCardWindowSidebar={openCardWindowSidebar}

            sidebarOpen={sidebarOpen} 
            sidebarClose={() => setSidebarOpen(false)} 
        />

        <div className="wall-container">

        <Routes>

          {/* Public Routes */}
          {/* <Route path="/" element={
            <Public 
              user={user}
              videoIdsPublic={videoIdsPublic} 
              setVideoIdsPublic={setVideoIdsPublic} 
              fetchVideoIdsPublic={fetchVideoIdsPublic}
              
              foldersPublic={foldersPublic}
              loadFoldersPublic={loadFoldersPublic} 
              pagesPublic={pagesPublic}
              createNewPagePublic={createNewPagePublic}
              updatePageNamePublic={updatePageNamePublic}

              setActiveCardIndex={setActiveCardIndex}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}

              openCardWindowSidebar={openCardWindowSidebar} 
              closeCardWindowSidebar={closeCardWindowSidebar} 

              activeCardIndex={activeCardIndex}
          />} /> */}

          {/* <Route path={`public/pages/:pagename`}  element={
            <PublicPage 
              user={user}
              videoIdsPublic={videoIdsPublic} 
              setVideoIdsPublic={setVideoIdsPublic} 
              fetchVideoIdsPublic={fetchVideoIdsPublic}
              
              foldersPublic={foldersPublic}
              loadFoldersPublic={loadFoldersPublic} 
              pagesPublic={pagesPublic}
              createNewPagePublic={createNewPagePublic}
              updatePageNamePublic={updatePageNamePublic}

              setActiveCardIndex={setActiveCardIndex}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}

              loadFolders={loadFolders} 

              openCardWindowSidebar={openCardWindowSidebar} 
              closeCardWindowSidebar={closeCardWindowSidebar} 

              activeCardIndex={activeCardIndex}
            />} /> */}



          {/* Private Routes */}
          <Route path="/" element={
            // <PrivateRoute user={user}>
            <Home 
              user={user}
              videoIds={videoIds} 
              setVideoIds={setVideoIds} 
              fetchVideoIds={fetchVideoIds}
              
              folders={folders}
              pages={pages}
              createNewPage={createNewPage}
              updatePageName={updatePageName}

              activeCardIndex={activeCardIndex}
              setActiveCardIndex={setActiveCardIndex}

              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}

              loadFolders={loadFolders} 
              loadFoldersPublic={loadFoldersPublic}

              openCardWindowSidebar={openCardWindowSidebar} 
              closeCardWindowSidebar={closeCardWindowSidebar} 

              numberOfVideos={numberOfVideos}
              checkPlan={checkPlan}
              freeVideoLimit={freeVideoLimit}

              handlePricingModal={handlePricingModal}
              isPricingModalOpen={isPricingModalOpen}
              setPricingModalOpen={setPricingModalOpen}

          />
          // </PrivateRoute>
          } />

          <Route path={`/pages/:pagename`} element={
            // <PrivateRoute user={user}>
            <Page 
            user={user}
            videoIds={videoIds} 
            setVideoIds={setVideoIds} 
            fetchVideoIds={fetchVideoIds} 

            folders={folders}
            pages={pages}
            createNewPage={createNewPage}
            updatePageName={updatePageName}

            activeCardIndex={activeCardIndex} 
            setActiveCardIndex={setActiveCardIndex}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}

            loadFolders={loadFolders} 

            openCardWindowSidebar={openCardWindowSidebar} 
            closeCardWindowSidebar={closeCardWindowSidebar} 

            numberOfVideos={numberOfVideos}
            checkPlan={checkPlan}
            freeVideoLimit={freeVideoLimit}

            handlePricingModal={handlePricingModal}
            isPricingModalOpen={isPricingModalOpen}
            setPricingModalOpen={setPricingModalOpen}
            />
            // </PrivateRoute>
            } />


          <Route path={`/notes`} element={
            <PageNotes
              user={user}
              videoIds={videoIds} 
              setVideoIds={setVideoIds} 
              fetchVideoIds={fetchVideoIds} 
              loadFolders={loadFolders} 
              openCardWindowSidebar={openCardWindowSidebar} 
              closeCardWindowSidebar={closeCardWindowSidebar} 
              activeCardIndex={activeCardIndex} 
            />} 
          />


          <Route path="/watchlater" element={
            // <PrivateRoute user={user}>
            <WatchLater 
              user={user}
              videoIds={videoIds} 
              setVideoIds={setVideoIds} 
              fetchVideoIds={fetchVideoIds}
              
              folders={folders}
              pages={pages}
              createNewPage={createNewPage}
              updatePageName={updatePageName}

              activeCardIndex={activeCardIndex}
              setActiveCardIndex={setActiveCardIndex}

              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}

              loadFolders={loadFolders} 

              openCardWindowSidebar={openCardWindowSidebar} 
              closeCardWindowSidebar={closeCardWindowSidebar} 

              numberOfVideos={numberOfVideos}
              checkPlan={checkPlan}
              freeVideoLimit={freeVideoLimit}

              handlePricingModal={handlePricingModal}
              isPricingModalOpen={isPricingModalOpen}
              setPricingModalOpen={setPricingModalOpen}

          />
          // </PrivateRoute>
          } />

            <Route path="/success" element={
            <CheckoutSuccess />} />

            <Route path="/cancel" element={
            <CheckoutCancel />} />

            <Route path="/deletesuccess" element={
            <DeleteSuccess />} />



          {/* <Route path="/edit" element={<TextEditor />} />
          <Route path="/jodit" element={<JoditPage />} /> */}
        </Routes>
      </div>
      </div>
        <div className='contact'><a href="mailto:noteworkspaceapp@gmail.com">noteworkspaceapp@gmail.com</a></div>
      </div>
    </>
  );
}


export default App;



// {
//   "rules": {
//     ".read": true,   // Allows read access at all times
//     ".write": true   // Allows write access at all times
//   }
// }

// {
//   "rules": {
//     "users": {
//       "$uid": {
//         ".read": "$uid === auth.uid",
//         ".write": "$uid === auth.uid"
//       }
//     }
//   }
// }

// {
//   "rules": {
//     "users": {
//       "$uid": {
//         ".read": "$uid === auth.uid",
//         ".write": "$uid === auth.uid && root.child('users/' + $uid + '/dataSizeInKB').val() < 100"  // Write only if data size is less than 100 KB
//       }
//     }
//   }
// }
