import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig';

import SidebarPublic from '../components/SidebarPublic';
import AddAVideoPublic from '../components/AddAVideoPublic';
import Cards from '../components/Cards';

const ITEMS_PER_PAGE = 100;

function Public({user, videoIdsPublic, foldersPublic, setVideoIdsPublic, fetchVideoIdsPublic, loadFoldersPublic, openCardWindowSidebar, closeCardWindowSidebar, activeCardIndex, setActiveCardIndex,
    pagesPublic, setPagesPublic, createNewPagePublic, updatePageNamePublic, sidebarOpen, setSidebarOpen
    }) {
    const [folders, setFolders] = useState([]);
    const [videoIds, setVideoIds] = useState([]);
    const [pages, setPages] = useState([]);
    const navigate = useNavigate();

    // Pagination using URL search params
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get('page')) || 1;

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Get the current page path
    const currentPath = window.location.pathname;

    const sidebarClose = () => {
        setSidebarOpen(false);
    }



        useEffect(() => {
        fetchVideoIdsPublic();
        loadFoldersPublic();
        }, [window.location.pathname]);


    // Callback to handle video addition
    const handleVideoAdded = () => {
        fetchVideoIdsPublic();
    };
    // END LOAD ALL VIDEOS






    // ADD A VIDEO
    const addVideoId = async (newVideoId, notes = "<h1>Title</h1><h2>Subtitle</h2><p><span style='font-size: 11px;'>📹 Video Link:&nbsp;</span><a href='https://www.youtube.com/watch?v=" + newVideoId + "' target='_blank' style='font-size: 11px;'>https://www.youtube.com/watch?v=" + newVideoId + "</a></p><p><br/></p><hr><p>📝 Notes</p><hr><p><br/></p><p><br/></p><p><br/></p>") => {

        // Determine the Firebase path based on the URL
        const publicPath = 'users/public/videos/';
        const videoPath = `${publicPath}${newVideoId}`;
        const publicRef = ref(database, publicPath);
        const videoRef = ref(database, videoPath);

        try {
        // Check if the /home node exists
        const publicSnapshot = await get(publicRef);

        if (!publicSnapshot.exists()) {
            // Create the /home node if it doesn't exist
            await set(publicRef, {});
        }
        // Get all existing videos to determine the next order number
        const snapshot = await get(videoRef);
        const videos = snapshot.exists() ? snapshot.val() : {};

        const videosHome = publicSnapshot.exists() ? publicSnapshot.val() : {};

        // Determine the next order number, ensuring it handles an empty videos object
        const maxOrder = videosHome && Object.keys(videosHome).length > 0
            ? Math.max(...Object.values(videosHome).map(video => video.order || 0))
            : 0;
        const newOrder = maxOrder + 1;

        // Create a new video entry
        const newEntry = {
            videoId: newVideoId, notes, order: newOrder, name: "Video - " + newVideoId
        };

        // Add the new video to Firebase with videoId as the key
        await set(videoRef, newEntry);

        // Update local state (assuming setVideoIds is updating the list of videos)
        setVideoIds(prevVideoIds => [...prevVideoIds, newEntry]);
        } catch (error) {
        console.error('Failed to add video:', error);
        }
    };
    // END ADD A VIDEO


  // DELETE A VIDEO
        const deleteVideoId = async (videoIdToDelete) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this video and its notes?");

        // console.log(videoIdToDelete);
        if (confirmDelete) {
            // Determine the Firebase path based on the URL
            let firebasePath = `users/public/videos/${videoIdToDelete}`;
            const videoRef = ref(database, firebasePath);

            try {
                await remove(videoRef); // Remove the video entry from the database
                // console.log(`Deleted ${videoIdToDelete} from ${firebasePath}`);
            } catch (error) {
                console.error(`Error deleting ${videoIdToDelete} from ${firebasePath}:`, error);
            }
    
            // Refresh the list after deletion
            fetchVideoIdsPublic();
            loadFoldersPublic();
        }
    };



    return (
        <>

            <h1>🗣 Public</h1>

            <AddAVideoPublic
                onVideoAdded={handleVideoAdded}
                addVideoId={addVideoId}
            />

            <Cards
                videoIds={videoIdsPublic}
                fetchVideoIds={fetchVideoIdsPublic}
                startIndex={startIndex}
                endIndex={endIndex}
                deleteVideoId={deleteVideoId}
                openCardWindowSidebar={openCardWindowSidebar}
                activeCardIndex={activeCardIndex}
                closeCardWindowSidebar={closeCardWindowSidebar}
            />

        </>
    );
};

export default Public;