// src/firebase/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, query, orderByChild, equalTo, set, get, push, update, remove } from 'firebase/database'; // Import necessary Firebase functions
import { getAuth } from "firebase/auth"; // Correct import for auth

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app); // Initialize database
const auth = getAuth(app); // Initialize the Auth service

// Export necessary Firebase functions
export { database, auth, ref, onValue, query, orderByChild, equalTo, set, get, push, update, remove };
export default app;