import React from 'react';
import { ref, update } from 'firebase/database';
import { database } from '../firebase/firebaseConfig';
const BACKEND_URL = "https://www.noteworkspace.com/api"

function CancelSubscriptionButton({ user, subscriptionId, disabled, tooltip }) {
    const handleCancelSubscription = async () => {
        // Show a confirmation dialog
        const confirmed = window.confirm("Are you sure you want to cancel your subscription?");

        // If the user clicks "Cancel", stop the cancellation process
        if (!confirmed) return;
        try {
            const response = await fetch(`${BACKEND_URL}/cancel-subscription/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subscriptionId }),
            });

            if (!response.ok) {
                console.error('Server error details:', data);  // Log error details from the backend
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // Check if data.cancel_at exists and is valid
            if (!data.cancel_at || isNaN(data.cancel_at)) {
                throw new Error('Invalid cancellation date');
            }

            // Get the date when the subscription will end
            const cancelAtDate = new Date(data.cancel_at * 1000);

            // Format the date to display the month and day (e.g., "September 14, 2024")
            const formattedCancelAtDate = cancelAtDate.toLocaleDateString(undefined, {
                month: 'long',  // Display full month name (e.g., "September")
                day: 'numeric', // Display day as a number (e.g., "14")
                year: 'numeric' // Optional, include the year (e.g., "2024")
            });

            // Update the user's subscription info in Firebase
            const userRef = ref(database, `users/${user.uid}/subscriptionInfo`);

            await update(userRef, {
                canceldate: cancelAtDate,  // Save the cancel date in Firebase
            });

            console.log('Subscription canceled:', data);
            alert(`Subscription canceled successfully. You can continue using Note Workspace until ${formattedCancelAtDate}`);
        } catch (error) {
            console.error('Error canceling subscription:', error);
            alert('Failed to cancel subscription');
        }
    };

    return (
        <button onClick={handleCancelSubscription} disabled={disabled} tooltip="">
            Cancel Subscription
        </button>
    );
}

export default CancelSubscriptionButton;
